var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "slideX", mode: "out-in" } },
        [
          !_vm.showOtp
            ? _c(
                "div",
                [
                  _c(
                    "h5",
                    {
                      staticClass:
                        "md:text-2xl text-xl text-center font-bold primary-text leading-10",
                    },
                    [_vm._v(" Identity verification ")]
                  ),
                  _c(
                    "p",
                    { staticClass: "text-center text-grey md:text-lg mt-2" },
                    [
                      _vm._v(
                        " We only use your BVN to verify that your account belongs to you. "
                      ),
                    ]
                  ),
                  _c("Input", {
                    staticClass: "mt-12",
                    attrs: {
                      width: "w-full",
                      placeholder: "Bank Verification Number",
                      maxlength: "11",
                      errorText: "BVN must be 11 digits only",
                      validation: _vm.rules.bvn,
                      number: "",
                    },
                    model: {
                      value: _vm.bvn,
                      callback: function ($$v) {
                        _vm.bvn = $$v
                      },
                      expression: "bvn",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "dial-method flex items-center" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          color: "#0EA2F5",
                          type: "mdi",
                          path: _vm.svgPath.warning,
                        },
                        nativeOn: {
                          click: function ($event) {
                            _vm.showExplainer = !_vm.showExplainer
                          },
                        },
                      }),
                      _c("p", { staticClass: "ml-2" }, [
                        _vm._v(" Dial "),
                        _c("span", { staticClass: "text-brandPurple" }, [
                          _vm._v("*565*0# "),
                        ]),
                        _vm._v("on your registered number to get your BVN. "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "explainer" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "explainer-heading flex items-center justify-between",
                        },
                        [
                          _c("p", [_vm._v("Here are what we have access to")]),
                          _c(
                            "div",
                            { staticClass: "flex -items-center" },
                            [
                              _c("p", { staticClass: "text-sm" }, [
                                _vm._v(
                                  _vm._s(_vm.showExplainer ? "Hide" : "Show")
                                ),
                              ]),
                              _c("svg-icon", {
                                staticClass: "cursor-pointer",
                                attrs: {
                                  color: "#0EA2F5",
                                  type: "mdi",
                                  path: _vm.svgPath.arrowDown,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    _vm.showExplainer = !_vm.showExplainer
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("AnimateHeight", [
                        _vm.showExplainer
                          ? _c("div", [
                              _c("div", { staticClass: "explainer-body" }, [
                                _c("p", [_vm._v("Your name")]),
                                _c("p", { staticClass: "mt-2" }, [
                                  _vm._v("Your selfie"),
                                ]),
                                _c("p", { staticClass: "mt-2" }, [
                                  _vm._v("Your birthday"),
                                ]),
                                _c("p", { staticClass: "mt-2" }, [
                                  _vm._v("Your number"),
                                ]),
                              ]),
                              _c("div", { staticClass: "explainer-footer" }, [
                                _c("p", [
                                  _vm._v(
                                    " Please note that providing your BVN does not give us access to your banking information or financial transactions "
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _c("Button", {
                    staticClass: "mt-8",
                    attrs: {
                      text: "Continue",
                      width: "w-full",
                      loading: _vm.loading,
                      disabled: _vm.disableButton,
                    },
                    on: { click: _vm.handleBvnLookup },
                  }),
                ],
                1
              )
            : _c("Otp", {
                attrs: { phoneNumber: _vm.userDetails.maskedPhoneNumber },
              }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showBvnInfo },
          on: {
            close: function ($event) {
              _vm.showBvnInfo = false
            },
          },
        },
        [
          _c("h5", { staticClass: "font-bold text-lg text-center e mt-4" }, [
            _vm._v(" We will like to confirm you: "),
          ]),
          _c(
            "p",
            { staticClass: "font-semibold text-center mt-2 text-brandPurple" },
            [_vm._v(" " + _vm._s(_vm.userDetails.name) + " ")]
          ),
          _c(
            "p",
            { staticClass: "font-semibold text-center mt-2 text-brandPurple" },
            [_vm._v(" " + _vm._s(_vm.userDetails.maskedPhoneNumber) + " ")]
          ),
          _c("Button", {
            staticClass: "mt-16",
            attrs: {
              text: "Yes, kindly go ahead",
              width: "w-full",
              loading: _vm.otpLoading,
            },
            on: { click: _vm.handleBvnSubmit },
          }),
          _c(
            "p",
            {
              staticClass:
                "my-8 text-center text-sm cursor-pointer text-grey underline",
              on: { click: _vm.handleNoAccess },
            },
            [_vm._v(" No, I don’t have access to this number ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }