<template>
  <div>
    <transition name="slideX" mode="out-in">
      <div v-if="!showOtp">
        <h5
          class="md:text-2xl text-xl text-center font-bold primary-text leading-10"
        >
          Identity verification
        </h5>
        <p class="text-center text-grey md:text-lg mt-2">
          We only use your BVN to verify that your account belongs to you.
        </p>
        <Input
          width="w-full"
          placeholder="Bank Verification Number"
          class="mt-12"
          maxlength="11"
          v-model="bvn"
          errorText="BVN must be 11 digits only"
          :validation="rules.bvn"
          number
        />
        <div class="dial-method flex items-center">
          <svg-icon
            @click.native="showExplainer = !showExplainer"
            color="#0EA2F5"
            type="mdi"
            :path="svgPath.warning"
          ></svg-icon>
          <p class="ml-2">
            Dial <span class="text-brandPurple">*565*0# </span>on your
            registered number to get your BVN.
          </p>
        </div>
        <!-- explainer container -->
        <div class="explainer">
          <div class="explainer-heading flex items-center justify-between">
            <p>Here are what we have access to</p>
            <div class="flex -items-center">
              <p class="text-sm">{{ showExplainer ? "Hide" : "Show" }}</p>
              <svg-icon
                @click.native="showExplainer = !showExplainer"
                class="cursor-pointer"
                color="#0EA2F5"
                type="mdi"
                :path="svgPath.arrowDown"
              ></svg-icon>
            </div>
          </div>
          <AnimateHeight>
            <div v-if="showExplainer">
              <div class="explainer-body">
                <p>Your name</p>
                <p class="mt-2">Your selfie</p>
                <p class="mt-2">Your birthday</p>
                <p class="mt-2">Your number</p>
              </div>
              <div class="explainer-footer">
                <p>
                  Please note that providing your BVN does not give us access to
                  your banking information or financial transactions
                </p>
              </div>
            </div>
          </AnimateHeight>
        </div>
        <Button
          text="Continue"
          class="mt-8"
          width="w-full"
          @click="handleBvnLookup"
          :loading="loading"
          :disabled="disableButton"
        />
        <!-- <p
          class="text-font-semibold text-brandPurple text-center cursor-pointer mt-8"
          @click="skipBvn"
        >
          Skip, I will do it later
        </p> -->
      </div>
      <Otp v-else :phoneNumber="userDetails.maskedPhoneNumber" />
    </transition>
    <Modal :display="showBvnInfo" @close="showBvnInfo = false">
      <h5 class="font-bold text-lg text-center e mt-4">
        We will like to confirm you:
      </h5>
      <!-- <p class="mt-4 text-grey text-center">
        We will like to confirm if this is you:
      </p> -->
      <p class="font-semibold text-center mt-2 text-brandPurple">
        {{ userDetails.name }}
      </p>
      <p class="font-semibold text-center mt-2 text-brandPurple">
        {{ userDetails.maskedPhoneNumber }}
      </p>

      <Button
        text="Yes, kindly go ahead"
        class="mt-16"
        width="w-full"
        :loading="otpLoading"
        @click="handleBvnSubmit"
      />

      <p
        class="my-8 text-center text-sm cursor-pointer text-grey underline"
        @click="handleNoAccess"
      >
        No, I don’t have access to this number
      </p>
    </Modal>
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { Input } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  import { Modal } from "@/UI/Modals";
  import { KYC_STEPS } from "../../data";
  import { mdiChevronDown, mdiAlertCircleOutline } from "@mdi/js";
  import AnimateHeight from "@/UI/AnimateHeight";
  export default {
    components: {
      Input,
      Button,
      Otp: () => import("./Otp.vue"),
      Modal,
      AnimateHeight,
    },
    data: () => ({
      showOtp: false,
      bvn: "",
      loading: false,
      showBvnInfo: false,
      userDetails: {},
      otpLoading: false,
      svgPath: {
        arrowDown: mdiChevronDown,
        warning: mdiAlertCircleOutline,
      },
      showExplainer: false,
    }),
    computed: {
      ...mapState({
        userData: (state) => state?.auth?.user,
        bvnValid: (state) => state?.auth?.user?.identityNumberVerified,
      }),
      rules() {
        return {
          bvn: this.bvn.length === 11,
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
    },
    methods: {
      ...mapActions("auth", ["bvnLookup", "sendBvnOtp"]),
      ...mapActions("dashboard", ["handleKycStep"]),
      ...mapActions("notification", ["showToast"]),
      handleProgress() {
        this.showBvnInfo = false;
        setTimeout(() => {
          this.handleKycStep(KYC_STEPS.phone);
        }, 1000);
      },
      handleBvnSubmit() {
        this.otpLoading = true;
        this.sendBvnOtp(this.bvn)
          .then(() => {
            this.showBvnInfo = false;
            this.otpLoading = false;
            this.showOtp = true;
          })
          .catch(() => {
            this.otpLoading = false;
          });
      },
      handleBvnLookup() {
        this.loading = true;
        this.bvnLookup(this.bvn)
          .then(({ data }) => {
            // bvn number is perisisted in vuex
            this.userDetails = data.data;
            this.loading = false;
            // a case there the user has done adding phone number as a result of skipping steps, just take the user to camera step
            if (this.userData?.phoneVerified) {
              this.handleKycStep(KYC_STEPS.camera);
            } else {
              this.showBvnInfo = true;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      handleNoAccess() {
        this.showBvnInfo = false;
        setTimeout(() => {
          this.handleKycStep(KYC_STEPS.camera);
        }, 1000);
      },
      skipBvn() {
        this.handleKycStep(KYC_STEPS.phone);
      },
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";
  .explainer {
    background: #f5fbff;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 1rem;
  }
  
  .explainer-heading p {
    font-size: 14px;
    color: #0ea2f5;
  }
  .explainer-body {
    padding: 18px 0;
    border-top: 1px solid #ededf0;
    border-bottom: 1px solid #ededf0;
    margin-top: 22px;
  }
  .explainer-body p {
    color: #6b6873;
    font-size: 14px;
  }
  .explainer-footer {
    padding-top: 16px;
  }
  .explainer-footer p {
    font-size: 14px;
    color: #6b6873;
  }

  .dial-method {
    background: #f9f9fb;
    border-radius: 4px;
    padding: 14px;
    margin-top: 24px;
  }
  .dial-method p {
    font-size: 13px;
    color: #6b6873;
  }
</style>
